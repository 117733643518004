@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.navbar-content{
    background-color: var(--bg-color);
    display:flex;
    justify-content: space-between;
    align-items:center;
    flex-direction: row;
    width:100%;
    
    a{
        color: var(--accent-color);
        font-family: var(--font-base);
        text-decoration: none;
        margin: 0 1rem;
        font-size: 1.5rem;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
        &:hover{
            color: var(--primary-color);
        }
    }

    @include media-breakpoint-up(md) { 
        .navbar-right{
            margin: 0 rem;
            justify-content: flex-end;
            align-items: row;
        }
    }


}

