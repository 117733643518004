@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

.profile-box{

    background-color: var(--bg-color);
    font-family: var(--font-base);
    font-size: 1.5rem;
    font-weight: 500;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);

    h1{
        color: var(--primary-color);
        font-family: var(--font-base);
        font-size: 2rem;
        font-weight: 500;
    }
    .line{
        border-bottom: 1.5px solid var(--primary-color);
        margin-top: -10px;
    }
    span{
        color: var(--primary-color);
        font-family: var(--font-base);
        font-weight: 500;
    }
}
@include media-breakpoint-down(sm) { 
    .profile-box{
        font-size: 1rem;
    }
}

@include media-breakpoint-up(lg) { 
    .profile-box{
        font-size: 2rem;
        span{
            margin-right: 50px;
        }
    }
}